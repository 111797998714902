<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="manualReview(scoped.row)"
            >
              人工审核
            </el-button>
            <el-button
              type="primary"
              @click="viewReceipt(scoped.row)"
              size="small"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { freeze, unblock, guestPage } from '@/api/userManagement/user'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键词以搜索'
    },
    {
      title: '处理状态',
      label: 'status',
      type: 'options',
      options: [
        { value: 0, label: '已处理' },
        { value: 1, label: '未处理' }
      ],
      placeholder: '请选择'
    },
    {
      title: '客诉时间',
      label: 'time',
      type: 'datetimerange'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    time: []
  },
  labelWidth: '70px',
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'nickName', label: '上传人（微信昵称）' },
    { prop: 'phone', label: '手机号' },
    { prop: 'region', label: '客诉时间' },
    { prop: 'newUserName', label: '已识别订单号' },
    { prop: 'stateName', label: '处理状态' },
    { prop: 'stateName', label: '客诉说明' },
    { slot: 'action' }
  ]
})

onMounted(() => {
  // getDataList()
})

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    time: []
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.state = fromData.value.status
  }
  if (fromData.value.member !== '') {
    data.newUser = fromData.value.member
  }
  guestPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      res.data.records.forEach(item => {
        item.stateName = item.state == 1 ? '正常' : '封号'
        item.newUserName = item.newUser ? '是' : '否'
      })
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 人工审核
const manualReview = row => {
  ElMessageBox.confirm(
    '改为不通过后本次核销记录将作废（本操作不可逆），是否确认？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    unblock({
      id: row.id
    }).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  })

  ElMessageBox.confirm(
    '改为通过后将正常发放积分（本操作不可逆），是否确认？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    unblock({
      id: row.id
    }).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}

// 查看小票
const viewReceipt = row => {
  ElMessageBox.confirm('封号后该会员将无法参与活动，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      freeze({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth
} = toRefs(initData)
</script>
<style lang="scss" scoped></style>
